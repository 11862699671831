/*
 * @Author: 蒋威
 * @Date: 2022-04-16 14:34:17
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-10 20:06:00
 * @Description: 活动
 */

import request from '@/utils/request'
// 活动分类分页查询
export function activityClassList(params) {
  return request({
    url: `/api/web/activityClassList`,
    method: 'get',
    params,
  })
}
// 活动分页查询
export function activityList(params) {
  return request({
    url: `/api/web/activityList`,
    method: 'get',
    params,
  })
}
// 活动分类分页查询
export function activityInfo(id, params) {
  return request({
    url: `/api/web/activityInfo/${id}`,
    method: 'get',
    params,
  })
}
// 活动报名人数
export function activityApplys(id, params) {
  return request({
    url: `/api/web/activityApplys/${id}`,
    method: 'get',
    params,
  })
}
